import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('tr');
  const [activeTab, setActiveTab] = useState('main');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedRow, setSelectedRow] = useState(0);

  // Saat ve tarih güncelleyici
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleLanguage = () => {
    const newLang = language === 'tr' ? 'en' : 'tr';
    setLanguage(newLang);
    // @ts-ignore
    i18n.changeLanguage(newLang);
  };

  const formatTime = () => {
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const formatDate = () => {
    const day = currentTime.getDate().toString().padStart(2, '0');
    const month = (currentTime.getMonth() + 1).toString().padStart(2, '0');
    const year = currentTime.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleKeyNav = (index: number) => {
    setSelectedRow(index);
  };

  return (
    <div className="App">
      <Helmet>
        {/* @ts-ignore */}
        <title>{t('title')}</title>
        <meta name="description" content="Furkan Tirit - Full Stack Developer" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="language-toggle">
        <button onClick={toggleLanguage}>
          {language === 'tr' ? 'EN' : 'TR'}
        </button>
      </div>

      {/* BIOS Header */}
      {/* @ts-ignore */}
      <div className="bios-header">{t('bios.header')}</div>

      {/* BIOS Menu */}
      <div className="bios-menu">
        {/* @ts-ignore */}
        <div 
          className={`bios-menu-item ${activeTab === 'main' ? 'active' : ''}`}
          onClick={() => setActiveTab('main')}
        >
          {/* @ts-ignore */}
          {t('bios.menu.main')}
        </div>
        <div 
          className={`bios-menu-item ${activeTab === 'advanced' ? 'active' : ''}`}
          onClick={() => setActiveTab('advanced')}
        >
          {/* @ts-ignore */}
          {t('bios.menu.advanced')}
        </div>
        <div 
          className={`bios-menu-item ${activeTab === 'security' ? 'active' : ''}`}
          onClick={() => setActiveTab('security')}
        >
          {/* @ts-ignore */}
          {t('bios.menu.security')}
        </div>
        <div 
          className={`bios-menu-item ${activeTab === 'boot' ? 'active' : ''}`}
          onClick={() => setActiveTab('boot')}
        >
          {/* @ts-ignore */}
          {t('bios.menu.boot')}
        </div>
        <div 
          className={`bios-menu-item ${activeTab === 'exit' ? 'active' : ''}`}
          onClick={() => setActiveTab('exit')}
        >
          {/* @ts-ignore */}
          {t('bios.menu.exit')}
        </div>
      </div>

      {/* BIOS Content */}
      <div className="bios-content">
        <div className="bios-main">
          {/* System Information Section */}
          {/* @ts-ignore */}
          <div className="section-heading">{t('bios.system.title')}</div>
          
          <table className="system-table">
            <tbody>
              <tr onClick={() => handleKeyNav(0)} className={selectedRow === 0 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.time_label')}</td>
                <td>{formatTime()} {selectedRow === 0 && <span className="bios-cursor"></span>}</td>
              </tr>
              <tr onClick={() => handleKeyNav(1)} className={selectedRow === 1 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.date_label')}</td>
                <td>{formatDate()} {selectedRow === 1 && <span className="bios-cursor"></span>}</td>
              </tr>
            </tbody>
          </table>

          {/* Hardware Information */}
          {/* @ts-ignore */}
          <div className="section-heading">{t('bios.system.hardware_title')}</div>
          
          <table className="system-table">
            <tbody>
              <tr onClick={() => handleKeyNav(2)} className={selectedRow === 2 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.processor_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.processor_value')} {selectedRow === 2 && <span className="bios-cursor"></span>}</td>
              </tr>
              <tr onClick={() => handleKeyNav(3)} className={selectedRow === 3 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.cache_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.cache_value')} {selectedRow === 3 && <span className="bios-cursor"></span>}</td>
              </tr>
              <tr onClick={() => handleKeyNav(4)} className={selectedRow === 4 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.memory_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.memory_value')} {selectedRow === 4 && <span className="bios-cursor"></span>}</td>
              </tr>
              <tr onClick={() => handleKeyNav(5)} className={selectedRow === 5 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.disk_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.disk_value')} {selectedRow === 5 && <span className="bios-cursor"></span>}</td>
              </tr>
            </tbody>
          </table>

          {/* Capabilities */}
          {/* @ts-ignore */}
          <div className="section-heading">{t('bios.system.capabilities_title')}</div>
          
          <table className="system-table">
            <tbody>
              <tr onClick={() => handleKeyNav(6)} className={selectedRow === 6 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.web_tech_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.web_tech_value')} {selectedRow === 6 && <span className="bios-cursor"></span>}</td>
              </tr>
              <tr onClick={() => handleKeyNav(7)} className={selectedRow === 7 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.prog_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.prog_value')} {selectedRow === 7 && <span className="bios-cursor"></span>}</td>
              </tr>
              <tr onClick={() => handleKeyNav(8)} className={selectedRow === 8 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.system.dev_tools_label')}</td>
                {/* @ts-ignore */}
                <td>{t('bios.system.dev_tools_value')} {selectedRow === 8 && <span className="bios-cursor"></span>}</td>
              </tr>
            </tbody>
          </table>

          {/* Personal Info */}
          {/* @ts-ignore */}
          <div className="section-heading">{t('bios.personal.title')}</div>
          
          <table className="system-table">
            <tbody>
              <tr onClick={() => handleKeyNav(9)} className={selectedRow === 9 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.personal.developer_label')}</td>
                <td>
                  {/* @ts-ignore */}
                  {t('name')} {selectedRow === 9 && <span className="bios-cursor"></span>}
                </td>
              </tr>
              <tr onClick={() => handleKeyNav(10)} className={selectedRow === 10 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.personal.role_label')}</td>
                <td>
                  {/* @ts-ignore */}
                  {t('role')} {selectedRow === 10 && <span className="bios-cursor"></span>}
                </td>
              </tr>
              <tr onClick={() => handleKeyNav(11)} className={selectedRow === 11 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.personal.education_label')}</td>
                <td>
                  {/* @ts-ignore */}
                  {t('education')} {selectedRow === 11 && <span className="bios-cursor"></span>}
                </td>
              </tr>
              <tr onClick={() => handleKeyNav(12)} className={selectedRow === 12 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.personal.phone_label')}</td>
                <td>
                  {/* @ts-ignore */}
                  {t('contact.phone')} {selectedRow === 12 && <span className="bios-cursor"></span>}
                </td>
              </tr>
              <tr onClick={() => handleKeyNav(13)} className={selectedRow === 13 ? 'highlight' : ''}>
                {/* @ts-ignore */}
                <td>{t('bios.personal.email_label')}</td>
                <td>
                  {/* @ts-ignore */}
                  {t('contact.email')} {selectedRow === 13 && <span className="bios-cursor"></span>}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div className="bios-help">
          {/* @ts-ignore */}
          <div className="bios-help-title">{t('bios.help.title')}</div>
          {/* @ts-ignore */}
          <p style={{ whiteSpace: 'pre-line' }}>{t('bios.help.desc')}</p>
        </div>
      </div>

      {/* BIOS Footer */}
      <div className="bios-footer">
        <div className="footer-item">
          {/* @ts-ignore */}
          <span className="footer-key">{t('bios.footer.f1')}</span> 
          {/* @ts-ignore */}
          {t('bios.footer.f1_action')}
        </div>
        <div className="footer-item">
          {/* @ts-ignore */}
          <span className="footer-key">{t('bios.footer.enter')}</span> 
          {/* @ts-ignore */}
          {t('bios.footer.enter_action')}
        </div>
        <div className="footer-item">
          {/* @ts-ignore */}
          <span className="footer-key">{t('bios.footer.esc')}</span> 
          {/* @ts-ignore */}
          {t('bios.footer.esc_action')}
        </div>
        <div className="footer-item">
          {/* @ts-ignore */}
          <span className="footer-key">{t('bios.footer.f10')}</span> 
          {/* @ts-ignore */}
          {t('bios.footer.f10_action')}
        </div>
        <div className="footer-item">
          {/* @ts-ignore */}
          <span className="footer-key">{t('bios.footer.arrows')}</span> 
          {/* @ts-ignore */}
          {t('bios.footer.arrows_action')}
        </div>
      </div>
    </div>
  );
}

export default App;
